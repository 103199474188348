function Navbar() {
  return (
    <div className="App">
      <header class="header">
        {/* <div class="header-wrap">
          <div class="logo plain logo-left">
            <div class="site-title">
              <a href="/" title="Go to Home">
                Camille & Hakim
              </a>
            </div>
          </div>
          <h1 class="title-menu-mobile">Menu</h1> */}

        <nav class="navbar navbar-expand-lg navbar-light bg-light">
          <a class="navbar-brand" href="/">
            Camille & Hakim
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav">
              <li class="nav-item">
                <a class="nav-link" href="/">
                  Accueil
                </a>
              </li>
              <li class="nav-item">
                <a href="/programme" class="nav-link">
                  Programme
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/hebergement">
                  Hébergement
                </a>
              </li>
              <li class="nav-item">
                <a href="/liste" class="nav-link">
                  Liste de mariage
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSfm5na7xJhn6H8_FMZQemyEzoF4TL3Iw-HlBFf9S2XRmwMdtQ/viewform?usp=pp_url"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Répondre
                </a>
              </li>
            </ul>
          </div>
        </nav>

        {/* <nav id="nav" role="navigation">
            <div class="table">
              <div class="table-cell">
                <ul id="menu-menu-1">
                  <li class="menu-item">
                    <a href="/">Accueil</a>
                  </li>
                  <li class="menu-item">
                    <a href="/programme">Programme</a>
                  </li>
                  <li class="menu-item">
                    <a href="/hebergement">Hébergement</a>
                  </li>
                  <li class="menu-item"  class="nav-link">
                    <a href="/liste"  class="nav-link">Liste de mariage</a>
                  </li>
                  <li class="menu-item">
                    <a
                      href="https://docs.google.com/forms/d/1X8ucvsn4vourY4t8z0ShQtSRorpH9NifVxrYSYa2ArE/prefill"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Répondre
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav> */}
        {/* </div> */}
      </header>
    </div>
  );
}

export default Navbar;
