import styled from "styled-components";

const Liste = () => {
  return (
    <Container>
      <div class="wrap full-wrap">
        <div class="main-wrap">
          <section class="main main-archive">
            <div class="loop">
              <article class="post format-gallery post_format-post-format-gallery">
                <div class="inner">
                  <h2 className="title">Liste de mariage</h2>

                  <div class="post-content">
                    <p>
                      Une urne sera disponible sur place si vous souhaitez nous
                      gâter.
                    </p>
                    <p>
                      Si vous preferez gérer ça en ligne, vous trouverez notre
                      liste de mariage{" "}
                      <a
                        href="https://www.millemercismariage.com/camilleethakim/liste.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        en cliquant sur ce lien
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </article>
            </div>
          </section>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 40px;

  .title {
    text-align: center;
    padding-bottom: 50px;
    font-size: 60px;
  }

  @media only screen and (max-device-width: 1000px) {
    .title {
      font-size: 100px;
    }

    .post-content {
      font-size: 35px;
    }
  }
`;

export default Liste;
