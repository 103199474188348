import styled from "styled-components";
import Nous from "../../assets/IMG_1272.jpeg";
import Nous2 from "../../assets/IMG_6733.jpg";

const Home = () => {
  return (
    <Container>
      <div class="main-text">
        <h1 className="container">
          <div class="title">Bienvenue sur le site de notre mariage</div>
          <div className="date">Le 13 juillet 2024</div>
          <div className="lieu">Au Manoir des Prévanches</div>

          <div className="lieu">27120, Boisset les Prévanches </div>
        </h1>
      </div>
      <div class="part-link">
        <a
          class="more-link"
          href="https://docs.google.com/forms/d/e/1FAIpQLSfm5na7xJhn6H8_FMZQemyEzoF4TL3Iw-HlBFf9S2XRmwMdtQ/viewform?usp=pp_url"
          title="Donner ma réponse à votre invitation"
          target="_blank"
          rel="noopener noreferrer"
        >
          Répondre à l'invitation
        </a>
      </div>
    </Container>
  );
};

const Container = styled.div`
  background-image: url(${Nous2});
  width: 100%;
  height: calc(100% - 77px);
  padding: 40px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center -536px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .main-text {
    color: #7c9f8e;
    width: 30%;
    display: flex;
    justify-content: flex-end;
    text-align: center;
    position: absolute;
    right: 30px;

    .container {
      background-color: #ffffffc4;
      padding: 20px;
      border-radius: 20px;

      .title {
        color: #737171;
        font-size: 45px;
        padding-bottom: 20px;
      }

      .date {
        padding-bottom: 20px;
        font-size: 70px;
      }

      .lieu {
        color: #737171;
        font-size: 40px;
      }
    }
  }

  .more-link {
    background-color: #ffffffc4;
    font-size: 27px;
  }

  .part-link {
    position: absolute;
    width: calc(100% - 80px);
    bottom: 10px;
  }

  @media only screen and (max-device-width: 1000px) {
    justify-content: flex-end;
    align-items: center;
    background-position: center;
    background-size: 171%;
    height: calc(100% - 133px);

    .part-link {
      top: 10px;
    }

    .main-text {
      width: 100%;
      position: relative;
      right: 0;
    }

    .title {
      font-size: 106px !important;
    }

    .date {
      font-size: 90px !important;
    }

    .lieu {
      font-size: 54px !important;
    }

    .more-link {
      font-size: 71px !important;
    }

    .container {
      max-width: 100%;
    }
  }
`;

export default Home;
